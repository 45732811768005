
*:focus {
    outline: none;
}
/*14FF0C*/
/* new code base */
body {
    margin: 0;
    padding: 0;
    /*width: 100%;*/
    /*overflow: hidden;*/
    /*background-color: #1d2634;*/
    color: #141D22;
    font-family: 'Montserrat', sans-serif;
    /*text-transform: capitalize;*/
}

.icon {
    vertical-align: middle;
    line-height: 1px;
    font-size: 20px;
}

.icon_header {
    vertical-align: middle;
    line-height: 1px;
    font-size: 26px;
}

.icon, .icon_header {
    /*margin-bottom: 10px;*/
    margin-right: 5px;
}

/*#141D22*/
.close_icon {
    color: red;
    margin-left: 30px;
    margin-top: 10px;
    cursor: pointer;
}

.grid-container {
    display: grid;
    grid-template-columns: 200px 1fr 1fr 1fr;
    /*grid-template-columns: 202px 1fr 1fr 1fr;*/
    grid-template-rows: auto 1fr; /* Changed 0fr to auto for better flexibility */
    grid-template-areas:
    'sidebar header header header'
    'sidebar main main main';
    height: 100vh;
    background-color: #FFFFFF;
    width: 100%;
    text-transform: capitalize;
}

/* Header */
.header {
    background-color: #141D22;
    grid-area: header;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;
    box-shadow: 0 6px 7px -3px rgba(0, 0, 0, 0.35);
    text-transform: capitalize;
    /*flex-direction: row;*/
}

@media (max-width: 768px) {
    .grid-container {
        margin: 0; /* Moved margin setting to .grid-container in the media query */
    }

    .header {
        background-color: #24273A; /* Moved media query for background color to .header */
        padding: 0 10px;
    }
}

.hidden {
    display: none;
}






.header-right {
    cursor: pointer;
}

.Nicon {
    margin-left: 30px;
    font-size: 18px;
    color: #ffffff;
}


.menu-icon {
    display: none;
}

/* End Header  */

/* Sidebar  */
#sidebar {
    grid-area: sidebar;
    height: 100%;
    width: 210px;
    padding: 3px;
    color: #141D22;
    background-color: #ffffff;
    /*background-color: #F8F9F9;*/
    overflow-y: auto;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.sidebar-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 30px 0px 30px;
    margin-bottom: 30px;
    color: #141D22;
}

.sidebar-title > span {
    display: none;
}

.sidebar-brand {
    margin-top: 15px;
    font-size: 20px;
    font-weight: 700;
}


/*background-color: #121521;*/


.Dash-sidebar{
    background-color: #121521
}
.sidebar-list {
    padding: 0;
    list-style-type: none;
    text-transform: capitalize;
}

.sidebar-list-item {
    padding: 8px;
    font-size: 12px;
    justify-content: flex-start;
    text-align: start;
    border-radius: 10px;
    margin: 10px;
    color: #141D22;
}

.sidebar-list-item, .icon {
    margin-bottom: 5px;
    color: #141D22;
}

.sidebar-list-item:hover {
    background-color: rgba(255, 222, 2, 0.1);
    color: #141D22;
    cursor: pointer;
}

.sidebar-list-item > a {
    text-decoration: none;
    color: #141D22;
}



.sidebar-responsive {
    display: inline !important;
    position: absolute;
    z-index: 12 !important;
}

/* End Sidebar  */


/* Main  */
.main-container {
    grid-area: main;
    width: 100%;
    /*overflow-y: auto;*/
    overflow-y: initial;
    padding: 15px 25px;
    color: #141D22;
    /*color: rgba(255, 255, 255, 0.95);*/
    /*margin: 0 1rem;*/
    /*color: #1C2127;*/
    /*margin: 1rem;*/
    /*box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);*/
    position: relative;
}

.dash-container {
    grid-area: main;
    /*overflow-y: auto;*/
    /*overflow-y: initial;*/
    padding: 0 0;
    color: #141D22;
    position: relative;
    background-color: #121521;
}


.main-title {
    display: flex;
    justify-content: space-between;
}

.main-cards {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 20px;
    margin: 15px 0;
}

.card {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 8px 15px;
    border-radius: 5px;
}

.card:first-child {
    background-color: #2962ff;
}

.card:nth-child(2) {
    background-color: #ff6d00;
}

.card:nth-child(3) {
    background-color: #2e7d32;
}

.card:nth-child(4) {
    background-color: #d50000;
}

.card-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.card-inner > .card_icon {
    font-size: 25px;
}

.charts {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin-top: 60px;
    height: 300px;
}

/* End Main  */


/* Medium <= 992px */

@media only screen and (max-width: 600px) {
    .CiWallet-m{
        display: block !important;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .CiWallet-m{
        display: block !important;
    }

}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .CiWallet-m{
        display: block !important;
    }
}


/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .CiWallet-m{
        display: none !important;
    }

}

@media screen and (max-width: 992px) {
    .grid-container {
        /*background-color: #141D22;*/
        color: #141D22;
        grid-template-columns: 1fr;
        grid-template-rows: 0.2fr 3fr;
        grid-template-areas:
      'header'
      'main';
    }

    #sidebar {
        display: none;
        color: #141D22;
        /*background-color: #141D22;*/
    }

    .menu-icon {
        display: inline;
    }

    .header-left img {
        width: 100%;
        margin-top: 0;
        /*display: none;*/
    }

    .sidebar-title > span {
        display: inline;
    }

    .CiWallet-w{
        display: none !important;
    }
    /*.CiWallet-m{*/
    /*    display: block !important;*/
    /*}*/
    .Nicon-m {
        margin-left: 30px;
        font-size: 18px;
        color: #ffffff;
    }

}

/* Small <= 768px */

@media screen and (max-width: 768px) {
    .main-cards {
        grid-template-columns: 1fr;
        gap: 10px;
        margin-bottom: 0;
    }

    .charts {
        grid-template-columns: 1fr;
        margin-top: 30px;
    }

}

/* Extra Small <= 576px */

@media screen and (max-width: 576px) {

    .main-container {
        grid-area: main;
        /*overflow-y: hidden;*/
        padding: 5px 10px;
        color: rgba(255, 255, 255, 0.95);
        margin-bottom: 20px;
        height: 100%;
    }

    .dash-container {
        margin-top:-3rem;
        grid-area: main;
        /*overflow-y: auto;*/
        padding: 0 0;
        color: rgba(255, 255, 255, 0.95);
    }

    .hedaer-left {
        display: none;
    }

    .open-icon {
        color: #14FF0C;
        font-size: 25px;
    }

    .close_icon {
        color: #141D22;
        margin-left: 6rem;
        /*margin-top: 5px;*/
        cursor: pointer;
        font-size: 30px;
    }
}
