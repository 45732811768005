.navbar {
    border-bottom: 1px solid #32383F;
    background: #37434E;
    height: 60px;
    flex-shrink: 0;
    /*display: flex;*/
    padding-right: 32px;
    color: #FFCF01;
}

.navbar__content {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    gap: 32px;
    justify-content: space-between;
}

.navbar__content--logo {
    /*border-right: 1px solid var(--secondary-black-tint-3);*/
    height: 50px;
    flex-shrink: 0;
    padding: 0px 32px 0px 28px;

    @media (max-width: 768px) {
        height: 30px;
        padding: 0px 20px 0px 18px;
    }
}


.navbar__content ul {
    list-style-type: none;
    display: flex;
    gap: 48px;
}

.navbar__content ul li {
    color: #A7B1BC;
    text-align: center;
    font-size: 14px;
    font-family: Satoshi;
    font-weight: 500;
    line-height: 16px;
}

.navbar__content ul li.activenav {
    color: var(--primary-angel-white, #FFF);
}

.navbar__search {
    display: flex;
    width: 412px;
    /* height: 32px; */
    padding: 10px 16px;
    flex-shrink: 0;
    border-radius: 16px;
    border: 1px solid var(--dark-mode-input-stroke, #373B3F);
    background: var(--dark-mode-card-bg, #20252B);
    gap: 16px;
    align-items: center;
}

.navbar__search input {
    background: transparent;
    color: var(--roqqu-grey-50, #A5B1BD);
    font-size: 12px;
    font-weight: 500;
    line-height: 150%;
    border: none;
    outline: none;
    width: 288px
}

.navbar__actions {
    display: flex;
    align-items: center;
    gap: 16px;

    @media (max-width: 768px) {
        padding: 0px 5px 0px 3px;
        margin-top: 10px;

    }
}


.topinfo {
    display: flex;
    width: 100%;
    padding: 16px;
    align-items: center;
    gap: 26px;
    justify-content: space-between;
    border-radius: 8px;
    border: 1px solid var(--dark-mode-card-stroke, #262932);
    background: var(--dark-mode-card-bg, #20252B);
}

.topinfo__info {
    display: flex;
    align-items: center;
}

.orderbook {
    width: 270px;
    /* height: 561px; */
    flex-shrink: 0;
    border-radius: 8px;
    border: 1px solid var(--dark-mode-card-stroke, #262932);
    background: var(--dark-mode-card-bg, #20252B);
    padding: 12px 8px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}


.buyAndSell {
    /*width: 255px;*/
    width: 300px;
    flex-basis: 300px;
    justify-content: center;
    align-content: center;
    /*flex-basis: 255px;*/
    flex-shrink: 0;
    border-radius: 2px;
    border: 1px solid var(--dark-mode-card-stroke, #262932);
    background: var(--dark-mode-card-bg, #20252B);
    padding: 14px 10px 50px 10px;
}

.buyAndSell__content {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.buyAndSell__content button {
    display: flex;
    width: 100%;
    padding: 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    background: var(--gradient-g-1, linear-gradient(90deg, #483BEB 0%, #7847E1 47.92%, #DD568D 96.35%));
    color: var(--primary-angel-white, #FFF);
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    border: none;
    outline: none;
}

.buyAndSell__content2 {
    display: flex;
    flex-direction: column;
    gap: 16px;
    border-top: 1px solid rgba(57, 64, 71, 0.5);
    margin-top: 16px;
    padding: 16px 0;
}

.chartHolder {
    position: relative;
    width: 100%;
    /* height: 100%; */
    padding: 0 8px;
    flex-grow: 1;
}

.indicators {
    display: flex;
    padding: 2px 0px;
    align-items: center;
    gap: 8px;
}

.indicators h1 {
    color: var(--secondary-black-tint-3, #A7B1BC);
    font-size: 14px;
    font-family: Satoshi;
    font-weight: 500;
    line-height: 16px;
}

.indicators__extras {
    display: flex;
    align-items: center;
    gap: 8px;
}

.footer {
    border: 1px solid var(--dark-mode-card-stroke, #262932);
    background: var(--dark-mode-card-bg, #20252B);
    padding-top: 50px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    gap: 50px;
    align-items: center;
    width: 100%;
}

.footer-content {
    display: flex;
    gap: 100px;
}

.footer-content__socials {
    display: flex;
    gap: 15px;
}

.social-icon,
.contact-link,
a,
.copyright {
    color: white;
    text-decoration: none;
}

.copyright {
    font-size: 14px;
}


@media screen and (max-width: 767px) {

    /* Styles for mobile devices */
    .navbar {
        border-bottom: 1px solid #32383F;
        background: #24273A !important;
        height: 72px;
        flex-shrink: 0;
        display: flex;
        justify-content: space-between;
        padding: 0 15px;
    }

    .navbar__search {
        display: none;
    }

    .navbar__content ul {
        /* display: non; */
        transform: translateX(200%);
        position: absolute;
        display: none;
        right: 10px;
        top: 60px;
        gap: 0;
        flex-direction: column;
        border-radius: 12px;
        border: 1px solid var(--dark-mode-input-stroke, #373B3F);
        background: #1C2127;
        box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08);
        width: 214px;
        padding: 8px 0px;
        z-index: 6;
        transition: 1s;
    }

    .navbar__content ul li {
        color: #A7B1BC;
        text-align: left;
        padding: 10px;
    }

    .topinfo__info {
        display: flex;
        align-items: center;
        width: 100%;
        overflow: auto;

    }

    .topinfo {
        flex-wrap: wrap;
        gap: 10px;
    }

    .orderbook {
        width: 100%;
        border: none;
        border-radius: 0%;
        display: none;
    }

    .navbar__content--logo {
        border-right: none;

    }

    .navbar__actions {

        gap: 10px;
    }

    .indicators {
        display: flex;
        flex-wrap: wrap;
        padding: 2px 0px;
        align-items: center;
        gap: 8px;
    }

    .buyAndSell {
        /* width: 255px; */
        position: fixed;
        z-index: 9;
        left: 0;
        right: 0;
        bottom: -50px;
        transform: translateY(100%);
        transition: 0.3s;
    }

    .footer {
        padding-top: 20px;
        gap: 30px;
    }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    /* Styles for tablets */
}