@import url('https://fonts.cdnfonts.com/css/satoshi');

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    /*text-transform: capitalize;*/
}

*:focus {
    outline: none;
}

body {
    font-family: 'Satoshi', sans-serif;
    color: #fff;
    width: 100%;
    /*overflow: hidden;*/
    /*cursor: pointer;*/
}




a {
    text-decoration: none;
    text-transform: capitalize;
    /*color: inherit;*/
}

.dashboard_body {
    text-transform: capitalize;
    /*background-color: #20252B;*/
    background-color: #121521;
    width: 100%;
}

.section {
    display: flex;
    flex-direction: column;
    width: 100%;
    /*background-color: #121521;*/
}

.section__extended {
    display: flex;
    justify-content: space-between;
    width: 100%;
    /*gap: 8px;*/
}

.extended {
    flex-grow: 1;

}


.tradingview {
    width: 100%;
    display: flex;
    justify-content: space-between;
    /*gap: 8px;*/
    /*margin-bottom: 8px;*/

}

.tradingview__chart {
    flex-grow: 1;
    background-color: #20252B;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    border: 1px solid #262932;
    transition: 1s;
    cursor: pointer;
    width: 95%;
}

.tradingview__filter {
    display: flex;
    padding: 16px;
    justify-content: space-between;
    align-items: flex-start;

}

.orders {
    width: 100%;
    border-radius: 8px;
    border: 1px solid #262932;
    background: #20252B;
    /*height: 561px;*/
    flex-shrink: 0;
    padding: 2rem;
}

.orders__tabs {
    display: flex;
    /*width: 640px;*/
    /*height: 32px;*/
    padding: 2px 10px;
    align-items: flex-start;
    flex-shrink: 0;
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.16);
    list-style-type: none;
}

.orders__tabs--links {
    display: flex;
    padding: 6px 17px 6px 20px;
    justify-content: center;
    align-items: center;
    flex: 1 0 0;
    align-self: stretch;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    color: #A7B1BC;
    cursor: pointer;
}

.orders__tabs--links.active {
    border-radius: 8px;
    color: #FFF;
    background: #21262C;
    box-shadow: 0px 3px 1px 0px rgba(0, 0, 0, 0.04), 0px 3px 8px 0px rgba(0, 0, 0, 0.12);
}

.orders__tabs--links:hover {
    color: #fff;
}

.orders__content {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    height: calc(100% - 32px);
}

.orders__content div {
    display: flex;
    width: 378px;
    flex-direction: column;
    align-items: center;
    gap: 8px;
}

.orders__content h1 {
    text-align: center;
    color: #FFF;
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
}

.orders__content h2 {
    text-align: center;
    color: #A7B1BC;
    font-size: 15px;
    font-weight: 500;
    line-height: 24px;
}

.Tab_Auth {
    background-color: #F8F9F9;
    overflow: hidden;
}


.Link {
    display: flex;
    justify-content: center;
    text-align: center;
    margin: 1rem;
    font-size: 14px;
    color: #007aff;
    background-color: #F8F9F9;
}



.auth-layout {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    padding: 10px;
    background-color: #fff;
}

.auth-layout .auth-layout-text {
    margin-left: 2.5rem;
}

@media screen and (max-width: 576px) {
    .auth-layout .auth-layout-text {
        margin-left: 0; /* Reset margin */
        text-align: center; /* Center text on smaller screens */
    }
}

.auth-layout-text {
    margin-top: 4rem;
    margin-bottom: 2rem;
    color: #1C2127;
}

.auth-container {
    /*background-color: #FFFFFF;*/
    width: 100%; /* Use full width of the parent container */
    max-width: 500px; /* Adjust width as needed */
    margin: 0 auto; /* Center horizontally */
    padding: 30px; /* Add padding for spacing */
    border-radius: 5px; /* Add rounded corners */
    /*box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);*/

}

.Document{

}

@media (max-width: 768px) {
    .auth-container{
        max-width: 500px;
    }
}

.auth-container__text {
    text-align: center;
}

.auth-container__text h1 {
    color: white;
    margin-bottom: 20px;
}

.auth-container__text h2 {
    color: var(--secondary-black-tint-3, #A7B1BC);
    text-align: center;
    font-size: 15px;
    font-weight: 500;
    line-height: 24px;
}

.auth-container__form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 2rem 1rem;
    text-transform: capitalize;

}

.auth-container__form div {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.auth-container__form .checkboxToggle {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 10px;
    color: rgb(108, 133, 149);
    cursor: pointer;
}

.auth-container__form .checkbox {
    display: flex;
    flex-direction: row;
    gap: 10px;
    /*margin-top: 1rem;*/
    color: rgb(108, 133, 149);
}

.MuiCheckboxMark {
    background-color: rgb(108, 133, 149);;
    width: 30px;
    height: 20px;
    color: #FFFFFF;
}

.text {
    font-size: 12px;
}

.error {
    font-size: 10px;
    color: red;
}

.togglePassword {
    position: absolute;
    top: 62%;
    right: 35rem;
    transform: translateY(-50%);
    cursor: pointer;
}


.auth-container__form .checkbox .checkbox_input {
    width: 25px;
}


.auth-container__form div label {
    color: #1C2127;
    font-size: 12px;
}

.auth-container__form input {
    border: 1px solid #A7B1BC;
    /*border: 1px solid #9e9ea4;*/
    /*border: 1px solid #373B3F;*/
    background: transparent;
    outline: none;
    color: #1C2127;
    font-size: 12px;
    border-radius: 3px;
    padding: 13px
}

.auth-container__form button {
    background: #f9ba32;
    color: #1C2127;
    outline: none;
    border: none;
    border-radius: 6px;
    padding: 10px;
    cursor: pointer;
    margin-top: 2rem;
}

.auth-container__form button:hover,
.git-container button:hover {
    /*background: #3e4350;*/
}

.git-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 400px;
    height: 90vh;
    gap: 20px;
    border-radius: 14px;
    padding: 20px;
    background-color: #21262C;
    box-shadow: 0px 3px 1px 0px rgba(0, 0, 0, 0.04), 0px 3px 8px 0px rgba(0, 0, 0, 0.12);
}

.git-container__gravater {
    width: 100px;
    height: 100px;
    border-radius: 100%;
}

.git-container__gravater img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 100%;
}


.git-container__repositories {
    height: calc(100% - 200px);
    position: relative;
}

.found {
    height: 100%;
}

.git-container__repositories #repositories {
    height: calc(100% - 80px);
    /*overflow-y: auto;*/
}

.scroll::-webkit-scrollbar {
    /*width: 5px;*/
    /* Set the width of the scrollbar */
}

.scroll::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
    /* Set the background color of the track */
}

.scroll::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
    /* Set the color of the scrollbar handle */
}

.scroll {
    scrollbar-width: thin;
    scrollbar-color: #f1f1f1 #888;
}

.git-container__repositories ul {
    list-style-type: none;
}

.git-container__repositories ul li {
    color: var(--secondary-black-tint-3, #A5B1BD);
    font-size: 14px;
    padding: 10px 5px;
}

.git-container__repositories ul a {
    text-decoration: none;
    color: inherit;
    text-transform: capitalize;
}

.git-container__repositories ul a:hover {
    color: white;
}

.git-container__repositories h1 {
    text-align: center;
    color: white;
    margin-bottom: 10px;
}

.git-container button {
    background: #353945;
    color: white;
    outline: none;
    border: none;
    border-radius: 10px;
    padding: 10px 30px;
    cursor: pointer;
}

.deskhide {
    display: none !important;
}

.deskShow {
    display: none !important;
}

.expand {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 10;
    /*width: 100% !important;*/
}

@media screen and (max-width: 767px) {

    .section__extended {
        flex-wrap: wrap;
        width: 100%;
    }

    .tradingview__chart {
        width: 100%;
        height: 80vh;
        border: none;
        padding: 0;
    }

    .orders__tabs {
        display: flex;
        width: 100%;
        /*display: none;*/
    }

    .orders__content div {
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: center;
        gap: 8px;
    }

    .orders__content h1 {
        text-align: center;
        font-size: 18px;
    }

    .orders__content h2 {
        text-align: center;
        font-size: 14px;
    }

    .tradingview {
        display: block;
        border-radius: 8px;
        border: 1px solid #262932;
        padding: 10px 5px;
        background-color: #20252B;
        width: 100%;
    }


    .deskhide {
        display: flex !important;
    }

    .deskShow {
        display: flex !important;
    }

    .tradingview__filter {
        width: 100% !important;
        flex-wrap: wrap;
        overflow-x: auto;
    }

    .orders {
        height: 300px;
        flex-shrink: 0;
        border-radius: 8px;
        padding: 16px;
    }

    .buyMobile {
        border: 1px solid #262932;
        background: #262B31;
        display: flex;
        /*width: calc(100vw - 30px);*/
        height: 64px;
        padding: 16px;
        justify-content: center;
        /* align-items: flex-start; */
        gap: 16px;
        flex-shrink: 0;
    }

    .buy {
        border-radius: 8px;
        background: #25C26E;
        display: flex;
        height: 32px;
        padding: 8px 50px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        flex-shrink: 0;
        border: none;
        outline: none;
        color: #FFF;
        font-size: 16px;
        font-weight: 700;
        line-height: 32px;
    }

    .sell {
        border-radius: 8px;
        background: #FF554A;
        display: flex;
        height: 32px;
        padding: 8px 50px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        flex-shrink: 0;
        border: none;
        outline: none;
        color: #FFF;
        font-size: 16px;
        font-weight: 700;
        line-height: 32px;
    }

    .git-container__repositories h1 {
        color: white;
        font-size: 20px;
    }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    /* Styles for tablets */
}
